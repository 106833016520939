import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";

import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      sanityHomePage {
        heroSmallTitle
        heroTitle
        heroDescription
        heroImage {
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              sizes: "(min-width: 1280px) calc(40vw - 40px), 80vw"
              formats: [AVIF, WEBP, AUTO]
            )
          }
        }
      }
    }
  `);

  const {
    sanityHomePage: {
      heroSmallTitle,
      heroTitle,
      heroDescription,
      heroImage: {
        asset: { gatsbyImageData },
      },
    },
  } = data;

  return (
    <Flex
      w="full"
      minH={{ lg: "6xl", xl: "90vh" }}
      align="center"
      justify="center"
      borderBottom="1px solid black"
      py={{ base: "8" }}
      mt={{ md: "20", xl: "none" }}
    >
      <SimpleGrid columns={{ base: 1, xl: 2 }} maxW="1800px">
        <Flex direction="column" justify="center" gridRow={{ base: 2, lg: 1 }} p={{ base: "8" }}>
          <Text color="primary">{heroSmallTitle}</Text>
          <Heading
            fontSize={{ base: "12vw", lg: "8vw", xl: "6vw" }}
            textTransform="uppercase"
            fontWeight={700}
          >
            {heroTitle}
          </Heading>
          <Text>{heroDescription}</Text>
        </Flex>
        <Box position="relative" p={{ base: "8" }}>
          <Box
            position="relative"
            border="1px solid black"
            mr={{ base: "16px", lg: "30px" }}
            mb={{ base: "16px", lg: "30px" }}
            _after={{
              content: "''",
              bg: "primary",
              position: "absolute",
              top: "0",
              left: "0",
              width: "full",
              height: "full",
              transform: { base: "translate(16px, 16px)", lg: "translate(30px, 30px)" },
              zIndex: -1,
              border: "1px solid black",
            }}
          >
            <GatsbyImage image={gatsbyImageData} style={{ zIndex: 20 }} loading="eager" />
          </Box>
        </Box>
      </SimpleGrid>
    </Flex>
  );
};

export default Hero;

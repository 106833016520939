import { Box, Flex, Heading, Link, SimpleGrid, Text, chakra } from "@chakra-ui/react";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Map from "@components/map";

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      sanityHomePage {
        contactHeadline
        contactDescription
        person
        email
        address
        long
        lat
      }
    }
  `);

  const { contactHeadline, contactDescription, person, email, address, long, lat } =
    data.sanityHomePage;

  const isBrowser = typeof window !== undefined;

  const mapContainer = useRef();

  const [mapSize, setMapSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (isBrowser) {
      const width = mapContainer.current.offsetWidth;
      const height = mapContainer.current.offsetHeight;

      const aspectRatio = height / width;

      if (width > height) {
        setMapSize({ width: 640, height: Math.trunc(640 * aspectRatio) });
      } else {
        setMapSize({ width: Math.trunc(640 * aspectRatio), height: 640 });
      }
    }
  }, []);

  return (
    <Box borderBottom="1px solid black" id="kontakt">
      <Box>
        <Heading
          fontSize={{ base: "4xl", lg: "6xl" }}
          textAlign="center"
          py="8"
          borderBottom="1px solid black"
        >
          {contactHeadline}
        </Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, lg: 2 }}>
        <Flex
          justify="center"
          align="center"
          borderRight={{ lg: "1px solid black" }}
          borderBottom={{ base: "1px solid black", lg: "none" }}
          py="12"
        >
          <Box p="8">
            <Text mb="8">{contactDescription}</Text>
            <Heading>Ansprechpartner</Heading>
            <Text>{person}</Text>
            <Text mb="8">
              <Link href={"mailto:" + email}>{email}</Link>
            </Text>
            <Heading>Anschrift</Heading>
            {address.split("\n").map((line, index) => (
              <Text key={index}>{line}</Text>
            ))}
          </Box>
        </Flex>
        <Box minH="xl" ref={mapContainer}>
          <Map lat={lat} lng={long} zoom={15} />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Contact;

import { Box, Heading } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";

import React from "react";
import Timeline from "@components/timeline";

const History = () => {
  const data = useStaticQuery(graphql`
    {
      sanityHomePage {
        history {
          title
          description
          time
          _key
        }
      }
    }
  `);

  const {
    sanityHomePage: { history },
  } = data;

  return (
    <Box borderBottom="1px solid black" id="historie">
      <Box>
        <Heading
          fontSize={{ base: "4xl", lg: "6xl" }}
          textAlign="center"
          py="8"
          borderBottom="1px solid black"
        >
          Unsere Historie
        </Heading>
      </Box>
      <Box px={{ base: "8" }}>
        <Timeline history={history} />
      </Box>
    </Box>
  );
};

export default History;

import Contact from "@components/homepage/contact";
import Hero from "@components/homepage/hero";
import History from "@components/homepage/history";
import Objects from "@components/homepage/objects";
import PartnerObjects from "@components/homepage/partnerObjects";
import React from "react";
import SEO from "@components/seo";
import { graphql } from "gatsby";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <>
      <SEO title={site.title} description={site.description} />
      <Hero />
      <Objects />
      <PartnerObjects />
      <History />
      <Contact />
    </>
  );
};

export default IndexPage;

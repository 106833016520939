import { Box, Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { Link, graphql, useStaticQuery } from "gatsby";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const transition = { duration: 0 };

const Objects = () => {
  const data = useStaticQuery(graphql`
    {
      sanityHomePage {
        objectsTitle
        objects {
          title
          objectExcerpt
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                sizes: "(min-width: 1280px) calc(30vw - 40px), 80vw"
                formats: [AVIF, WEBP, AUTO]
              )
            }
          }
        }
      }
    }
  `);

  const {
    sanityHomePage: { objectsTitle, objects },
  } = data;

  return (
    <Box id="objekte">
      <Box>
        <Heading
          fontSize={{ base: "4xl", lg: "6xl" }}
          textAlign="center"
          py="8"
          borderBottom="1px solid black"
        >
          {objectsTitle}
        </Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2, xl: objects.length }}>
        {objects.map((object, index) => (
          <ObjectGridItem
            key={index}
            object={object}
            index={index}
            isLast={index === objects.length - 1}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Objects;

const ObjectGridItem = ({ object, index, isLast }) => {
  const {
    title,
    objectExcerpt,
    slug: { current: objectSlug },
    mainImage: {
      asset: { gatsbyImageData },
    },
  } = object;

  console.log(object);
  return (
    <Flex
      gridColumn={{ md: isLast && index % 2 === 0 ? "span 2" : null, xl: "auto" }}
      direction="column"
      borderRight={{
        md: index % 2 !== 0 ? "none" : isLast && index % 2 === 0 ? "none" : "1px solid black",
        xl: !isLast ? "1px solid black" : "none",
      }}
      borderBottom={{
        base: "1px solid black",
        md: "1px solid black",
      }}
      px="8"
      py="12"
      justify="space-between"
    >
      <Box>
        <Box position="relative" pb="6">
          <MotionBox
            position="relative"
            border="1px solid black"
            mr={{ base: "16px", lg: "30px" }}
            mb={{ base: "16px", lg: "30px" }}
            layoutId={objectSlug}
            transition={transition}
            initial={false}
            animate={{
              transform: "none",
              transformOrigin: "50% 50% 0px",
            }}
            _after={{
              content: "''",
              bg: "primary",
              position: "absolute",
              top: "0",
              left: "0",
              width: "full",
              height: "full",
              transform: { base: "translate(16px, 16px)", lg: "translate(30px, 30px)" },
              zIndex: -1,
              border: "1px solid black",
            }}
          >
            <GatsbyImage image={gatsbyImageData} style={{ zIndex: 20 }} />
          </MotionBox>
        </Box>
        <Heading fontSize="4xl" mb="4">
          {title}
        </Heading>
        <Text mb="10">{objectExcerpt}</Text>
      </Box>
      <Link to={"/objekte/" + objectSlug} style={{ width: "100%" }}>
        <Button
          isFullWidth
          size="md"
          fontSize="1.5rem"
          h="12"
          colorScheme="brand"
          rightIcon={<ArrowForwardIcon />}
        >
          Mehr dazu
        </Button>
      </Link>
    </Flex>
  );
};
